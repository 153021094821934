@import "../variables.less";

nz-form-item {

  //input.ant-input {
  //  height: 32px;
  //}

  &.marginless {
    margin-bottom: 0;
  }
}

nz-form-label {
  //margin-bottom: 12px;

  label {
    font-family: @bold-font-family;
    color: @color-text;
    text-transform: capitalize;
  }

  &.text-transform-none {
    label {
      text-transform: none;
    }
  }

}

label.ant-checkbox-wrapper {
  font-size: @font-sizes[small];
}
