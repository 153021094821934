@import "./variables.less";

nz-content, app-layout, app-auth, .ant-drawer, .ant-modal, .ant-popover, app-notfound, app-forbidden, app-thankyou, nz-notification, .ant-dropdown {

  /******************************
  * Global primary button styles
  ******************************/
  button.primary-button {
    align-self: center;
    font-family: @bold-font-family;
    font-size: @font-sizes[base]; // fallback for when clamp() isn't supported
    font-size: clamp(12px, 1.3vw, 14px);
    color: @color-primary-white;
    background: @color-primary-main;
    box-shadow: none;
    text-shadow: none;
    border-radius: @button-border-radius;
    opacity: 1;
    outline: none;
    text-align: center;
    height: 35px;
    border: 0px;
    cursor: pointer;
    transition: all .1s linear;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 0 35px;
    white-space: normal;
    &.tdActionButton {
      font-size: 10px; // fallback for when clamp() isn't supported
      font-size: clamp(9px, 1.3vw, 10px);
      height: auto;
      padding: 2px 12px;
      box-shadow: 0px 2px 5px @color-grey-ash;
      font-family: @regular-font-family;
    }
    &:disabled,&:disabled:hover {
      cursor: default;
      box-shadow: 0px 0px #677380;
      color: #646464;
      background: #D9D9D9;
    }
    &.red-button {
      background: @color-warning-mid-dark;
      color: @color-primary-white;
    }
    &:hover,
    &:focus {
      background: @color-shade-slate-blue;
      color: @color-primary-white;
      box-shadow: 0px 3px 6px @color-grey-ash;
    }
    &:active {
      background: @color-primary-main 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px @color-grey-ash;
    }
    & > span {
      position: relative;
      z-index: 1;
      line-height: 1;
    }
  }


  /********************************
  * Global secondary button styles
  ********************************/
  button.secondary-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    border: 2px solid @color-primary-main;
    border-radius: @button-border-radius;
    height: 35px;
    color: @color-primary-main;
    font-size: @font-sizes[base];
    padding: 0 21px;
    outline:none;
    font-family: @bold-font-family;
    white-space: normal;
    display: inline-flex;
    align-items: center;
    line-height: 1.2;
    transition: all .1s linear;
    &.red-button {
      border-color: @color-warning-mid-dark;
      color: @color-warning-mid-dark;
    }
    &:hover,
    &:focus {
      color: @color-primary-white;
      border-color: @color-grey-iron;
      background: @color-grey-iron;
      cursor: pointer;
      &.red-button {
        color: @color-warning-mid-dark;
        border-color: @color-warning-mid-dark;
        background: @color-warning-empty;
      }
    }
    &:active {
      background: @color-shade-mid-light 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #1C494E1A;
    }
    &:disabled,&:disabled:hover {
      cursor: default;
      box-shadow: 0px 0px #677380;
      color: #c7c7c7;
      background: #ece6e6;
      border-color: #ece6e6;
    }
    fa-icon {
      margin-right: 7px;
    }
  }


  /**************************
  * Global link button styles
  ***************************/
  a.link-button {
    font-size: @font-sizes[base];
    font-family: @bold-font-family;
    color: @color-primary-main;
    &:hover {
      color: @color-grey-cool;
    }
    &.red-button {
      color: @color-warning-mid-dark;
    }
  }


  /*************************************
  * Global underlined link button styles
  **************************************/
  a.underlined-link-button,
  span.underlined-link-button,
  a[href*="mailto"]  {
    color: @color-primary-main;
    background: linear-gradient(0deg, @color-primary-main 0%, @color-primary-main 100%) no-repeat 0 100%/100% 0;
    text-decoration: underline;
    text-decoration-color: @color-primary-main;
    // text-decoration-thickness: 3px;
    text-underline-offset: 0.2em;
    text-decoration-skip-ink: none;
    transition: background-size 80ms ease-in;
    cursor: pointer;

    &:is(:hover,:focus,:active), [class*=underlined] a:is(:hover,:focus,:active) {
      color: @color-grey-cool;
      text-decoration-color: @color-grey-cool;
    }
  }


  /*********************************
  * Global additional button styles
  *********************************/
  button, a {
    &.small-padding {
      padding: 0 10px;
    }
    &.medium-padding {
      padding: 0 25px;
    }
    &.small-font,
    &.small-font span {
      font-size: 12px;
    }
    &.smaller-font,
    &.smaller-font span {
      font-size: 10px;
    }
    &.small-height {
      height: 30px;
    }
    &.smaller-height {
      height: 26px;
    }
    &.smallest-height {
      height: 20px;
    }
    &.match-form-item-height {
      margin: 32px 0 22.5px 0;
    }
    &.whitespace-nowrap {
      white-space: nowrap;
    }
    &.full-width {
      width: 100%;
      justify-content: center;
    }
  }

}


// Special cases for red button in a popover
// basic size adjustments
.popover-btn-size() {
  height: 24px;
  padding: 10px;
  font-size: 12px;
  line-height: 1;
}
// extending classes to customize secondary button in popover (and to be red)
.ant-popover .ant-btn:not(.ant-btn-primary) {
  &:extend(nz-content button.secondary-button);
  &:extend(nz-content button.secondary-button.red-button);
  .popover-btn-size();
  &:hover {
    &:extend(nz-content button.secondary-button:hover);
    &:extend(nz-content button.secondary-button:hover.red-button);
  }
}
// extending classes to customize primary button in popover (and to be red)
.ant-popover .ant-btn-primary {
  &:extend(nz-content button.primary-button);
  &:extend(nz-content button.primary-button.red-button);
  .popover-btn-size();
  &:hover {
    &:extend(nz-content button.primary-button:hover);
    &:extend(nz-content button.primary-button:hover.red-button);
  }
}

