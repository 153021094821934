
.ant-select-dropdown.app-site-tags{
    display: none;
}

nz-select, nz-tree-select {

  .ant-select-search--inline .ant-select-search__field {
    font-size: 13px;
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    font-size: 13px;
    border: none;
    background: #f5f5f5;
    border-radius: 3px;
    color: #768492;
    display: flex;
    align-items: center;
  }
   & > nz-select-top-control > nz-select-search {
     flex: 1;
   }
}

.app-site-tags .ant-select-selection--multiple {
  min-height: 10vh;
  max-height: 10vh;
  overflow: auto;
}


.ant-select-dropdown {
  .ant-select-dropdown-menu-item-disabled {
    cursor: default;
  }
}

.ant-select-selection-item {
  font-size: 12px;
}

.app-filters-container {

  //.ant-form-item-control-input {
  //  width: 200px;
  //}

  // nz-select-top-control {
  //   height: 32px;
  // }

  .ant-select-selection, input {
    border-radius: 5px;
  }

  nz-select .ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 5px;
  }

  nz-select .ant-select-selection__choice, .ant-select-selection-item {
    max-width: 6rem;
  }

  .ant-select-selection-item {
    padding-right: 5px !important;
  }

}

// idle & hover - border & icon color
nz-select:not(.ant-select-disabled) {
  nz-select-top-control {
    border-color: @color-grey-ash;
  }
  nz-select-arrow {
    i.anticon-down {
      transform: scale(0.7);
    }
    fa-icon {
      color: @color-grey-ash;
    }
  }
  &:hover {
    nz-select-top-control {
      border-color: @color-shade-darker;
    }
    nz-select-arrow {
      fa-icon {
        color: @color-shade-darker;
      }
    }
  }
}

// styles for dropdown overlay
.ant-select-dropdown {
  font-size: @font-sizes[small];
}


nz-select {
  all: unset;
  nz-select-placeholder {
    font-size: @font-sizes[small];
  }
  nz-select-top-control {
    flex-wrap: nowrap;
  }
}


