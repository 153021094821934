
.ant-form-item-with-help {
  margin-bottom: 8px;
}

.ant-form-item-label {
  line-height: inherit;
  font-size: @font-sizes[small];
  // margin-bottom: 2px;
  margin-bottom: 7px;

  label {
    font-family: @semibold-font-family;
    font-size: @font-sizes[small];
    color: @color-text;
    text-transform: capitalize;
  }
}

.ant-form-item-control {
  line-height: inherit;
}

.ant-input,
.ant-input-number,
nz-range-picker,
nz-select,
nz-select nz-select-top-control nz-select-item,
nz-tree-select nz-select-top-control nz-select-item {
  font-size: @font-sizes[small];
  height: 32px;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: @color-primary-black;
}

nz-input-group.ant-input-affix-wrapper {
  padding-block: 0;
  & > * {
    height: 30px;
  }
  i, fa-icon {
    color: @color-grey-ash;
  }
  &:hover {
    i, fa-icon {
      color: @color-shade-darker;
    }
  }
}
nz-input-group .ant-input-group-addon {
  i, fa-icon {
    color: @color-grey-ash;
  }
}
nz-input-group:hover .ant-input-group-addon {
  i, fa-icon {
    color: @color-shade-darker;
  }
}

nz-input-group:hover {
  .ant-select:not(.ant-select-disabled) .ant-select-selector,
  .ant-input-group-addon {
    border-color: @color-shade-darker;
  }
  i, fa-icon {
    color: @color-shade-darker;
  }
}

.monthly-volume.ant-input-number:hover{
  .ant-input-number-handler-wrap{
    opacity: 0;
  }
}
.ant-form-explain {
  font-size: @font-sizes[smallest];
  margin-top: 2px;
  min-height: auto;
}


// styles for when input validation returns an error
.has-error {
  .has-error();
}

.has-error(){
  .ant-form-item-label label {
    color: @color-error;
  }

  .ant-input {
    border-color: @color-error;
  }

  .ant-form-explain {
    color: @color-error;
  }
}

.ant-form-extra {
  min-height: auto;
}

.blue-extra-tip {
  font-size: @font-sizes[smallest];
  color: @color-teal;
  font-family: @semibold-font-family;
  cursor: default;
  .ellipsisText();
}


nz-form-item {
  &.marginless {
    margin-bottom: 0;
  }
}

.netsuite-required-icon {
  position: absolute;
  top: -1px;
  right: 0;
}

.formItemLabelExtraText {
  display: inline-block;
  position: absolute;
  top: 0;
  line-height: 1.2;
}
