nz-input-group {
  top: 0;

  span.ant-input-group-addon {
    //background: white;
    //color: @color-teal;
    font-size: @font-sizes[small];
  }

   nz-input-number.ant-input-number:has(+ span.ant-input-group-addon) {
    border-right: 1px solid transparent;
    .border-right-radius-0();

    input {
      .border-right-radius-0();
    }

    &.ant-input-number-disabled {
      & + span.ant-input-group-addon {
        background: #f5f5f5;
      }
    }
  }

  span.ant-input-group-addon + nz-input-number.ant-input-number {
    border-left: 1px solid transparent;
    .border-left-radius-0();

    input {
      .border-left-radius-0();
    }

    &.ant-input-number-disabled {
      & + span.ant-input-group-addon {
        background: #f5f5f5;
      }
    }
  }

  &.round-border input {
    border-radius: 20px;
  }
}

.border-right-radius-0() {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.border-left-radius-0() {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
