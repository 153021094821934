@import "./theme.less";
@import './segal-styles/variables.less';
@import './segal-styles/mixins.less';

/* You can add global styles to this file, and also import other style files */
.cdk-global-scrollblock { /* Fix for drawer making sidebar shift and brake */
  position: static !important;
  overflow-y: hidden !important;
}

.text-right {
  text-align: right;
}

.text-right-important {
  text-align: right !important;
}

button {
  cursor: pointer;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-grid {
  display: grid;
}

.display-grid-center {
  display: grid;
  place-items: center;
}

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.display-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-end {
  align-items: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.flex-1 {
  flex: 1;
}

.gap-1 {
  gap: 1rem;
}

.gap-small {
  gap: 0.5rem;
}

.gap-smaller {
  gap: 0.25rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-normal {
  line-height: normal;
}

.height-0 {
  height: 0;
}

.height-100 {
  height: 100%;
}

.background-transparent {
  background: transparent;
}

.z-index-1 {
  z-index: 1;
}

.isolation-isolate {
  isolation: isolate;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-clip {
  overflow-y: clip;
}

.no-cursor {
  cursor: not-allowed;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}


nz-sider {
  height: calc(100vh - 50px);
  overflow: auto;
  position: fixed !important;
  left: 0
}

nz-card.ant-card-bordered {
  border: 0 !important;
}

.dayPartingToolCont {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      border: 1px solid #e1e1e1;
      color: #768492;
      font-size: 12px;
      font-family: 'OpenSans Light';
      height: 32px;
      padding: 4px 13px;
      line-height: 23px;
      border-radius: 3px;

      .ant-checkbox {
        visibility: hidden;
        position: absolute;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;

        /*
          Introduced in Internet Explorer 10.
          See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
        */
        -ms-user-select: none;
        user-select: none;

        &.ant-checkbox-checked::after {
          visibility: hidden;
          border: 0;
          background: @color-teal;

        }
      }
    }

    .ant-checkbox-wrapper {
      &.checked {
        background: @color-teal 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        border: 1px solid @color-teal;
      }

    }
  }
}

.ant-input-affix-wrapper {
  .ant-input-prefix, .ant-input-suffix {
    z-index: 2;
  }
}

.ant-input-search .ant-input-affix-wrapper .ant-input-suffix .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-search.ant-input-search-enter-button .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}

.search .ant-input-affix-wrapper .ant-input-suffix .anticon.anticon-close-circle {
  margin-right: 10px;
}

.ant-tabs-tabpane .ant-tabs-tabpane-active {
  position: relative;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 0;
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}


nz-tree {
  padding-left: 4px !important;
}

//.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
//.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
//.ant-menu-submenu:hover {
//  border-bottom: none !important;
//}

.ant-table {
  background-color: #fff;
}

.ant-form label.ant-radio-wrapper {
  font-size: @font-sizes[small];
  align-items: flex-start;

  .ant-radio-inner {
    width: 12px;
    height: 12px;
  }
}

#avatar .ant-upload {
  width: 100%;
  height: 198px;
}

hr {
  border-color: #ddd;
  border-style: solid;
  border-bottom: 1px;
}

nz-range-picker.full-width,
nz-range-picker.full-width .ant-calendar-picker {
  width: 100%;
}

nz-range-picker.override-width .ant-calendar-picker {
  width: 100% !important;
}

.full-width {
  width: 100%;
}

.summary-content .ant-table {
  clear: unset;
}

.ant-select-arrow {
  svg {
    fill: @color-shade-darker;
  }
}

.tabs-vertical .ant-tabs-nav .ant-tabs-tab {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: unset;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 12px;
}

.tabs-vertical.tabs-notab .ant-tabs-nav .ant-tabs-tab:first-child {
  display: none;
}

.tabs-vertical .ant-tabs-nav-wrap {
  background-color: #fbfbfb;
}

.tabs-vertical.ant-tabs .ant-tabs-left-content {
  padding-left: 0;
}

.tabs-vertical .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  transform: rotate(90deg);
  vertical-align: unset;
}

.tabs-vertical.ant-tabs .ant-tabs-left-bar {
  border-right: 2px solid #e8e8e8;
}

.ant-layout-sider-trigger {
  height: 53px;
}

.page-search {
  float: right;
  margin-bottom: 16px;
  z-index: 1;
  width: 275px;
  margin-right: 16px;
}

.page-search .ant-input-group-addon {
  padding: 0;
  border: 0;
}

.page-search button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-search .ant-input-suffix {
  margin-right: 7px;
}

margin-bottom-regular {
  margin-bottom: 3.36rem;
}

nz-input-group.suffix-margin-right-1 {
  padding: 0;

  nz-select.top-control-no-border > nz-select-top-control {
    border: none !important;
    box-shadow: none !important;
  }
}

.suffix-margin-right-1 {
  .ant-input-suffix {
    margin: 0rem;
  }
}

.float-right {
  float: right;
}

.small-font,
nz-switch.small-font span.ant-switch-inner {
  font-size: @font-sizes[small];
}

.smaller-font,
nz-switch.smaller-font span.ant-switch-inner {
  font-size: @font-sizes[smallest];
  padding: 0 3px;
}

.light-font {
  font-family: @light-font-family;
}

.low-opacity {
  opacity: 0.4;
}

.regular-font {
  font-family: @regular-font-family;
}

.semibold-font {
  font-family: @semibold-font-family;
}

.bold-font {
  font-family: @bold-font-family;
}

.link-text {
  cursor: pointer;
  color: @color-shade-darker;
}

.text-center {
  text-align: center;
}

.red {
  color: @color-error;
}

.align-self-center {
  align-self: center;
}

.float-left {
  float: left;
}

.cursor-pointer {
  cursor: pointer;
}

.filter-invert-1 {
  filter: invert(1);
}

.above-table-search-buttons {
  float: right;
  margin-bottom: 16px;
  z-index: 1;
}

.badge {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
  padding: 2px 7px;
  border-radius: 50%;
  cursor: pointer;
}

.ellipsisText {
  .ellipsisText();
}

.ellipsisText() {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multilineEllipsisText(@lines) {
  --line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @lines; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: var(--line-height);
  max-height: calc(var(--line-height) * @lines);
}

.multilineEllipsisText-1 {
  .multilineEllipsisText(1);
}

.multilineEllipsisText-2 {
  .multilineEllipsisText(2);
}

.multilineEllipsisText-3 {
  .multilineEllipsisText(3);
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.border-none {
  border: none !important;
}

.ant-popover-inner-content,
.whitespace-normal {
  white-space: normal;
}

.background-white {
  background: #fff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px 12px 40px;
}

.ant-drawer .ant-drawer-content-wrapper.fullWidth {
  width: calc(100% - 246px) !important;
}

.ant-form-item {
  display: block;
}

.ant-form-item-explain-error {
  font-size: 10px;
}

.ant-select-selector {
  font-size: 12px;
}

.ant-picker-input > input, .ant-select-item-option-content {
  font-size: 12px !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: '';
  margin-right: 0;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  content: '*' !important;
  color: @color-primary-main;
  display: inline-block;
  align-self: baseline;
}

.ant-form-item-label > label::after {
  content: '';
}

.ant-modal {
  width: 50%;
}

.ant-upload.ant-upload-drag {
  background: white;
  border: 1px dashed @color-primary-main;
}

.match-form-item-height {
  margin: 32px 0 22.5px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
}

.match-form-item-no-label-height {
  margin: 24.5px 0 22.5px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
}

//.ant-drawer.ant-drawer-open .ant-drawer-mask {
//  opacity: 0.3;
//}


// collapsed menu dark mode fix
//  .ant-menu-submenu {
//    .ant-menu-item {
//      &:not(.ant-menu-item-selected) {
//        a {
//          color: rgba(255,255,255,0.65) !important;
//        }
//        &:hover {
//          a {
//            color: white !important;
//          }
//        }
//      }
//      &.ant-menu-item-selected {
//        a {
//          color: white !important;
//        }
//      }
//    }
//  }

.updateVersionMsg {
  display: inline-flex;
  align-items: center;
  position: fixed;
  background: #008844;
  margin-bottom: 10px;
  bottom: -100%;
  left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all .5s ease;
  cursor: default;

  &.isVisible {
    bottom: 0;
  }

  .refreshIcon {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .refreshContent {
    font-size: 11px;
    font-style: italic;
    margin-bottom: 1px;
  }
}



// styles to give a form a look of readonly
// (when disabling a form for readonly purposes just add 'form-readonly' class to a form/form-group & disabled controls).
.form-readonly {
  .ant-input[disabled] {
    color: inherit;
    border: 1px solid #eee;
    cursor: auto;

    &::placeholder {
      color: transparent;
    }
  }

  .ant-input-number-disabled {
    color: inherit;
    border: 1px solid #eee;
    cursor: default;

    input[disabled] {
      cursor: default;
    }

    &:hover {
      border-color: #eee;
    }
  }

  .ant-radio-group {
    label.ant-radio-button-wrapper-disabled {
      color: rgba(0, 0, 0, 0.65);
      background: inherit;
      border-color: #d9d9d9;
      cursor: default;
    }
  }

  .ant-select-disabled {
    .ant-select-selection {
      &:not(.ant-select-selection--single) {
        background: inherit;
        cursor: default;
        border: none;
      }

      &.ant-select-selection--single {
        color: #777;
        border: 1px solid #eee;
        cursor: auto;
      }

      li.ant-select-selection__choice {
        background: inherit;
        color: @color-teal;
        border: 1px solid;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selection__placeholder {
        margin-left: 0;
        color: #888;
      }
    }
  }

  label.ant-checkbox-wrapper {
    cursor: default !important;

    .ant-checkbox {
      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background: #fff;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: @color-shade-darker !important;
            background: @color-shade-darker;

            &:after {
              border-color: #fff;
            }
          }
        }
      }

      // checkbox label
      & + span {
        padding-left: 4px;
        color: #777;
      }
    }

    * {
      cursor: default !important;
    }
  }
}


.global-search-container {
  position: relative;

  &.open-to-left {
    padding-right: calc(20px + 1em) !important; // 20px is the globalSearch inner side paddings, 1em is the width of the search icon
  }
}

.ant-drawer {
  .global-search-container {
    &.open-to-left {
      padding-right: 0px !important;
    }
  }
}

.ant-calendar-picker {
  width: 100%;
}

nz-badge.ant-badge-status.update-version-dot {
  line-height: normal;

  .ant-badge-status-dot {
    position: absolute;
    transform: scale(1.5);
    outline: 1px solid #fff;
    background: @color-teal;
    z-index: 1;
    right: 0;
    top: 0;
  }
}

/* Code display */
pre {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}


@media screen and (max-width: 620px) {
  date-range-popup {
    .ant-calendar-range {
      width: 75vw;
      max-width: 350px;
    }

    .ant-calendar-date-panel {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 20px;
      font-size: 12px;

      .ant-calendar-range-part {
        width: 100%;

        .ant-calendar-date-input-wrap {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}

.section-title,
.sectionTitle {
  font-size: @font-sizes[base];
  font-family: @bold-font-family;
  display: flex;
  align-items: center;
  color: @color-shade-slate-blue;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
  margin: 25px 0 24px;
  line-height: 1;
  width: 100%;

  fa-icon, .icon {
    color: @color-grey-cool;
    font-size: 24px;
    margin-right: 18px;
  }

  &.borderless {
    border-bottom: none;
  }

  &.margin-bottom-none {
    margin-bottom: 0;
  }
}

.error-txt {
  color: @error-color;
  font-size: small;
  z-index: 1;
}

.error-txt-smaller {
  color: @error-color;
  z-index: 1;
  font-size: smaller;
}

.signup-footer {
  padding: 0 0 1rem 0;
  text-align: center;
}

.mimic-zorro-error-tip {
  color: @color-error;
  font-size: 10px;
  margin-top: 2px;
}

.mimic-form-explain-no-error {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
}

.capitalize-text {
  text-transform: capitalize;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.text-left {
  text-align: left;
}

@import "./segal-styles/segal-styles.less";
@import "./segal-styles/cardless-layout/cardless-layout.less";
