
nz-modal-container {
  .ant-modal-content {
    box-shadow: none;
    border-radius: 24px;
  }
  .ant-modal-footer {
    border-top: none;
    .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 1rem;
    }
  }
}
